import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { FluidImage } from '../types';

type ImageList = {
  edges: {
    node: FluidImage;
  }[];
};
type QueryResponse = {
  chart: FluidImage;
  dermal: FluidImage;
  subq: FluidImage;
  proves: ImageList;
  dermals: ImageList;
  subqs: ImageList;
  mix: ImageList;
};

type About = {
  media: {
    chart: FluidImage;
    dermal: FluidImage;
    subq: FluidImage;
  };
  proves: {
    title: string;
    image: FluidObject;
  }[];
  cases: {
    title: string;
    images: FluidImage[];
  }[];
};

export const useAboutQuery = (): About => {
  const { chart, dermal, subq, proves, dermals, subqs, mix } = useStaticQuery<
    QueryResponse
  >(graphql`
    query AboutQuery {
      chart: file(relativePath: { eq: "about/chart.png" }) {
        childImageSharp {
          fluid(maxWidth: 588) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dermal: file(relativePath: { eq: "about/dermal.png" }) {
        childImageSharp {
          fluid(maxWidth: 212) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      subq: file(relativePath: { eq: "about/subq.png" }) {
        childImageSharp {
          fluid(maxWidth: 212) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      proves: allFile(
        filter: { relativePath: { glob: "about/prove-*.png" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 278) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      dermals: allFile(
        filter: { relativePath: { glob: "about/cases/dermal-*.jpg" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            ...CaseImages
          }
        }
      }
      subqs: allFile(
        filter: { relativePath: { glob: "about/cases/subq-*.jpg" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            ...CaseImages
          }
        }
      }
      mix: allFile(
        filter: { relativePath: { glob: "about/cases/mix-*.jpg" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            ...CaseImages
          }
        }
      }
    }
  `);

  return {
    media: {
      chart,
      dermal,
      subq,
    },
    proves: ['膠原蛋白', '彈力蛋白', '玻尿酸'].map((title, i) => ({
      title,
      image: proves.edges[i].node.childImageSharp.fluid,
    })),
    cases: [
      { title: 'Dermal', images: dermals.edges.map(({ node }) => node) },
      { title: 'SubQ', images: subqs.edges.map(({ node }) => node) },
      { title: 'SubQ+Dermal', images: mix.edges.map(({ node }) => node) },
    ],
  };
};
