import React from 'react';
import {
  Button,
  Box,
  Heading,
  Text,
  Image,
  Flex,
} from 'rebass/styled-components';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { Fade, Slide } from 'react-awesome-reveal';
import QuoteLeft from '../assets/images/quote-left.svg';
import QuoteRight from '../assets/images/quote-right.svg';
import Needle from '../assets/images/needle.svg';
import { useAboutQuery } from '../queries/useAboutQuery';
import { PageHeading, SectionHeading, TechSection, QaAccordion } from '../components/';
import Layout from '../components/Layout';
import { useQaQueries } from '../queries/useQaQueries';
import {graphql} from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';

const About = (pageContext: any) => {
  const allWpQa = pageContext.data.allWpQa;
  const { media, proves, cases } = useAboutQuery();
  const nodes = useQaQueries();

  return (
    <Layout>

      {allWpQa.edges.map( (edge : any) => {
        <Seo post={edge.node} />
      })}

      <Box variant="container">
        <PageHeading subTitle="About Profound®">關於魔方</PageHeading>
        <Heading
          fontFamily="serif"
          fontSize={[18, null, 24]}
          color="primary"
          mb="16px"
        >
          魔方67：精準治療溫度67º 時間3秒
        </Heading>
        <Text fontSize={[14, null, 16]} mb={[16, 63]}>
          只有 Profound 最佳治療溫度+時間
          <br />
          能有效誘發彈力蛋白、膠原蛋白、玻尿酸新生！
        </Text>
        <Box width="100%" maxWidth={[320, null, 588]} mx="auto">
          <Img fluid={media.chart.childImageSharp.fluid} />
          <Slide triggerOnce direction="up" fraction={0.5}>
            <Box
              mt={[10, null, 65]}
              mb={50}
              pt={[20, null, 40]}
              pb={[10, null, 20]}
              sx={{
                backgroundImage: `url(${QuoteLeft}), url(${QuoteRight})`,
                backgroundPosition: `0 0, 100% 100%`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: ['42px 33px', null, '129px 102px'],
              }}
            >
              <Text
                textAlign="center"
                fontFamily="serif"
                fontSize={[null, null, 24]}
              >
                經臨床實驗數據顯示，一次魔方療程達到—
                <br />
                100%皺紋改善率、
                <br />
                95%鬆弛改善率、
                <br />
                94%橘皮改善率、
                <br />
                40%媲美手術拉皮效果。
              </Text>
            </Box>
          </Slide>
        </Box>
        <SectionHeading align="left">專利技術</SectionHeading>
        <Box px={[35, null, 0]} mb={[64, null, 95]}>
          <Flex justifyContent="center">
            <Flex
              flexDirection="column"
              alignItems={[null, null, 'center']}
              width={[null, null, '100%']}
              pr={[30, null, 0]}
              pl={[50, null, 0]}
              py={[24, null, 0]}
              color="white"
              backgroundColor={['primary', null, 'transparent']}
              sx={{
                position: 'relative',
              }}
            >
              <Image
                src={Needle}
                sx={{
                  position: ['absolute', null, 'static'],
                  left: '-24px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 48,
                  height: 48,
                }}
              />
              <Text
                color={['white', null, 'black']}
                fontFamily="serif"
                fontSize={[18, null, 24]}
                fontWeight="bold"
              >
                智能溫控針
              </Text>
              <Box
                backgroundColor={[null, null, 'primary']}
                fontSize={[14, null, 18]}
                alignSelf={[null, null, 'stretch']}
                mt={['8px', null, 18]}
                py={[null, null, 38]}
                sx={{
                  textAlign: [null, null, 'center'],
                  position: [null, null, 'relative'],
                }}
              >
                每次擊發皆獨立偵測肌膚電阻，並給與能量，使溫度維持在目標範圍。
                <Box
                  display={['none', null, 'block']}
                  bg="dark"
                  width={51}
                  height={1}
                  sx={{
                    position: [null, null, 'absolute'],
                    top: -1,
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                />
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Slide triggerOnce fraction={1} direction="left">
          <TechSection
            align="left"
            image={media.dermal}
            title="Dermal 探頭"
            slogan="改善鬆弛、皺紋"
          >
            作用在皮下1.0 - 2.0 mm ，針對真皮層膠原蛋白、彈力蛋白及玻尿酸增生。
          </TechSection>
        </Slide>
        <Slide triggerOnce fraction={1} direction="right">
          <TechSection
            align="right"
            image={media.subq}
            title="SubQ探頭"
            slogan="改善橘皮組織"
          >
            作用在皮下2.9 - 5.8 mm
            ，針對皮下組織膠原蛋白、彈力蛋白及玻尿酸增生。
          </TechSection>
        </Slide>
        <SectionHeading align="center">臨床實證</SectionHeading>
        <Heading
          display={['none', 'block']}
          fontFamily="serif"
          fontSize="24px"
          my="80px"
        >
          魔方67治療 10 週後
        </Heading>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          alignItems={['center', 'flex-end']}
        >
          {proves.map(({ title, image }) => (
            <Box
              key={title}
              mb={48}
              width={['100%', 'calc((100% - 32px * 2) / 3)']}
            >
              <Fade triggerOnce fraction={0.5}>
                <Img fluid={image} />
                <Text textAlign="center" mt="24px" fontSize={[18, 24]}>
                  {title}
                </Text>
              </Fade>
            </Box>
          ))}
        </Flex>
        <SectionHeading id="cases" align="right">
          案例分享
        </SectionHeading>
        {cases.map(({ title, images }) => (
          <Box key={title}>
            <Text as="h4"  fontFamily="serif" fontSize={[16, 24]} mb="24px">
              {title}
            </Text>
            <Flex justifyContent="space-between" flexWrap="wrap">
              {images.map((image, index) => (
                <Box
                  key={image.id}
                  width={['calc(50% - 4px)', 'calc(50% - 15px)']}
                  mb={[40, 48]}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Img fluid={image.childImageSharp.fluid} />
                  <Text as="h5" mt={['8px', '24px']}>
                    {index % 2 === 0 ? 'before' : 'after'}
                  </Text>
                </Box>
              ))}
            </Flex>
          </Box>
        ))}
        <Box mt={[24, 84]} mb={[80, 136]} sx={{ textAlign: 'center' }}>
          <Link to="/contact">
            <Button variant="outline" as="span" backgroundColor="#FFFFF9">
              預約諮詢
            </Button>
          </Link>
        </Box>
        <SectionHeading align="left">常見問題</SectionHeading>
        <Box mb={120}>
          {nodes.map((Qa) => (
            <Box key={Qa.id} mb={['16px', '24px']}>
              <QaAccordion {...Qa} />
            </Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query GET_PAGE_QA {
    allWpQa {
      edges {
        node {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
          }
        }
      }
    }
  }
`;