import { useStaticQuery, graphql } from 'gatsby';
import _get from 'lodash/get';
import { simpleFormat } from '../utils';
import { Qa } from '../types';

export type QueryResponse = {
  allWpQa: {
    edges: {
      node: {
        id: string;
        title: string;
        acf: {
          qasEdit: (
            | {
                fieldGroupName: 'qa_Acf_QasEdit_QasText';
                text: string;
              }
            | {
                fieldGroupName: 'qa_Acf_QasEdit_QasSubtitle';
                subtitle: string;
              }
          )[];
        };
      };
    }[];
  };
};

export const useQaQueries = (): Qa[] => {
  const { allWpQa } = useStaticQuery<QueryResponse>(graphql`
    {
      allWpQa {
        edges {
          node {
            id
            title
            acf {
              qasEdit {
                ... on WpQa_Acf_QasEdit_QasText {
                  fieldGroupName
                  text
                }
                ... on WpQa_Acf_QasEdit_QasSubtitle {
                  fieldGroupName
                  subtitle
                }
              }
            }
          }
        }
      }
    }
  `);

  return allWpQa.edges.map(({ node }) => {
    const {
      id,
      title,
      acf: { qasEdit },
    } = node;
    const contents = qasEdit.map((qasContent) => {
      const variant = {
        qa_Acf_QasEdit_QasSubtitle: 'subtitle',
        qa_Acf_QasEdit_QasText: 'text',
      }[qasContent.fieldGroupName] as 'subtitle' | 'text';

      return {
        variant,
        content: simpleFormat(_get(qasContent, [variant])),
      };
    });

    return {
      id,
      title,
      contents,
    };
  });
};
